import { Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <GatsbySeo title="About" />
      <div className="prose max-w-full">
        <h1 className="pt-12  pb-8 md:text-6xl">About</h1>

        <p>
          Boctoz（ボクトツ）は、知的なあなたに向けて素朴な教訓のある文章を発信するメディアです。
        </p>

        <p className="mt-12 font-semibold">Related pages</p>
        <ul>
          <li>
            <Link to="/author">Author</Link>
          </li>
          <li>
            <Link to="/rss.xml">Feed</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default AboutPage;
